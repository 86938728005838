import { navigate } from "gatsby";
import { Box, Image } from "grommet";
import React from "react";
import NavigationFrame from "../components/nav_frame";
import photo from "../images/404.webp";

class Page404 extends React.Component {
    
    render() {
        return (
            <NavigationFrame>
                <Box width={'full'} pad="medium"
                    onClick={() => navigate("/")}
                >
                    <Image src={photo} fit="contain"/>
                </Box>
            </NavigationFrame>
        )
    }
}

export default Page404;